@import '~@angular/material/theming';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');

$custom-typography: mat-typography-config($font-family: 'Rubik,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;',
    $display-4: mat-typography-level(112px, 112px, 300),
    $display-3: mat-typography-level(56px, 56px, 400),
    $display-2: mat-typography-level(45px, 48px, 400),
    $display-1: mat-typography-level(34px, 40px, 400),
    $headline: mat-typography-level(28px, 34px, 400),
    $title: mat-typography-level(24px, 32px, 500),
    $subheading-2: mat-typography-level(20px, 28px, 400),
    $subheading-1: mat-typography-level(20px, 28px, 400),
    $body-2: mat-typography-level(18px, 24px, 500),
    $body-1: mat-typography-level(18px, 24px, 400),
    $caption: mat-typography-level(18px, 24px, 400),
    $button: mat-typography-level(18px, 16px, 500),
    $input: mat-typography-level(18px, 1.125, 400));
// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat-base-typography($custom-typography);

// Override typography for a specific Angular Material components.
@include mat-checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a {
  background-color: transparent;
  color: #337ab7;
  text-decoration: none;
}

a:hover {
  background-color: transparent;
  color: #337ab7;
  text-decoration: underline;
}

.full-page {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.grow-item {
  height: 250px;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
}

.canceled {
  background-color: lightgray !important;

  >td {
    background-color: lightgray !important;
  }
}

.flip-list-move {
  transition: transform 1s !important;
}

.new-update {
  font-weight: bold;
}

@media (max-width: 720px) {
  .slim-dialog {
    .mat-dialog-content {
      padding: 8px !important;
    }

    .mat-dialog-title {
      margin: 0 -12px !important;
    }

    .mat-dialog-actions {
      margin: -12px -12px !important;
    }
  }


}

.mat-dialog-container {

  .mat-dialog-actions {

    padding-bottom: 0px !important;
  }
}

.mat-dialog-title {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}


.region-option {
  font-weight: bold;
}



.one-line-of-combos .mat-form-field-infix {
  width: inherit !important;
}


.table,
th,
td {
  border: 1px solid #ddd;
  padding: 10px 10px;
}

th {
  background-color: #f1f1f1;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.events-container {
  display: flex;
  flex-wrap: wrap;
}


.event-card {
  width: 290px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-radius: 10px;

  @media (max-width: 720px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  strong {
    @media (max-width: 720px) {
      font-weight: 600;
    }
  }
}

.drive-event-card {
  background-color: lightyellow;
}
